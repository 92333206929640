import Api from '@/api/index'
export function queryBonus() {
  return Api.check(
      '/api/member/stake/bonus/index',
      'post'
  )
}

export function queryBonusList(data) {
  return Api.check(
      '/api/member/stake/bonus/list',
      'post',
      data
  )
}

export function withdrawBonus() {
  return Api.check(
      '/api/member/stake/withdraw/stake/bonus',
      'post'
  )
}